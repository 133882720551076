<template>
  <v-container>
    <NotificationsTable dark />
  </v-container>
</template>

<script>
import NotificationsTable from "@/packages/admin/components/notification/NotificationsTable";
export default {
  name: "stuentNotification",
  components: { NotificationsTable },
};
</script>

<style scoped></style>
